<!--
	@name app-client-service-solicitor-referral-form
	@description Conveyancing Referral Form
	@date 2020/07/20
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<p class="text-h5 font-weight-bold mb-2">
			Equilaw
		</p>

		<p class="text-body-2 mb-6">
			When you instruct Equilaw to look after your clients' conveyancing through the Air Sourcing platform, you benefit not just from award-winning, specialised expertise and
			knowledge in the equity release sector. You'll also benefit from direct integration between the Equilaw and Air systems, resulting in less data input replication and
			increased visibility on case progress - all from within your Air Sourcing account.
		</p>

		<div v-if="form('conveyancing').value">
			<!-- Applicant Details-->
			<common-form-client
				ref="applicantDetails"
				:form="formFromGroup('conveyancing', 'client')"
				:clients="clientList"
				:show-error="submitFailed"
				edit-only
				@set-client="setApplicant"
				@set-validation="setValidation"
			/>

			<!-- Property Details -->
			<common-form-property
				ref="propertyDetails"
				:property-form="formFromGroup('conveyancing', 'asset')"
				:property="property"
				:client="clientList[0]"
				:show-error="submitFailed"
				edit-only
				@set-validation="setValidation"
				@set-property="setProperty"
			/>

			<common-form-section ref="product" title="Product Details" :form="formFromGroup('conveyancing', 'product')" :item="productDetails" @set-validation="setValidation" />

			<!-- Payment -->
			<common-form-section
				ref="payment"
				title="Your Payment Details"
				:form="formFromGroup('conveyancing', 'payment')"
				:item="yourPaymentDetails"
				@set-validation="setValidation"
			/>

			<!-- Information -->
			<common-form-section ref="information" title="Other Details" :form="formFromGroup('conveyancing', 'information')" :item="otherDetails" />

			<!-- Form Footer -->
			<div v-show="form('conveyancing').value" class="text-center">
				<v-alert v-show="!isValid" transition="slide-y-transition" dense prominent outlined text border="left" type="error" class="mb-6 text-body-1 text-left">
					You cannot proceed until ALL sections are validated. Please go back and address any sections displaying this error message.
				</v-alert>
				<v-btn color="primary" :loading="submitting" @click="submit">
					Submit
				</v-btn>
			</div>
		</div>
		<v-card v-else class="flex-grow-1 mb-6">
			<v-skeleton-loader type="list-item-three-line" />
		</v-card>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState } from 'vuex';
	import CommonFormClient from '@/component/common/form/client';
	import CommonFormProperty from '@/component/common/form/property';
	import CommonFormSection from '@/component/common/form/section';

	export default {
		name: 'app-client-dashboard-workflow-conveyancing-referral',

		components: { CommonFormClient, CommonFormProperty, CommonFormSection },

		props: {
			organisationId: { type: String, default: null },
			clientId: { type: String, required: true },
			associationId: { type: String, default: null },
			assetId: { type: String, required: true },
			submitting: { type: Boolean, required: true, default: false }
		},

		data() {
			return {
				submitFailed: false,
				clientList: [{}],
				property: {},
				otherDetails: {},
				yourPaymentDetails: {},
				productDetails: {},
				validations: {
					applicant: false,
					property: false
				}
			};
		},

		computed: {
			...mapState('Account', ['user']),
			...mapGetters('CmsForm', ['form', 'formFromGroup']),

			isValid() {
				return !Object.keys(this.validations).find((key) => !this.validations[key]);
			}
		},

		created() {
			this.loadUserDetails(this.user.id).then(({ data }) => {
				if (data.bank) this.yourPaymentDetails = { accountName: data.bank.name, accountNumber: data.bank.account, sortCode: data.bank.sortCode };
			});
			this.fetchForm();
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Account', ['loadUserDetails']),

			setApplicant({ dataIndex, data }) {
				this.$set(this.clientList, dataIndex, data);
				this.$nextTick(() => this.$refs.applicantDetails.validateForm());
			},

			setProperty(property) {
				this.property = property;
				this.$nextTick(() => this.$refs.propertyDetails.validateForm());
			},

			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			/**
			 * @name fetchForm
			 * @description Fetch conveyancing form
			 */
			fetchForm() {
				this.loadForm('conveyancing').then(() => {
					this.$refs.applicantDetails.fetchClient(0, this.clientId);
					if (this.associationId) this.$refs.applicantDetails.fetchClient(1, this.associationId);
					this.$refs.propertyDetails.fetchAsset(this.clientId, this.assetId);
				});
			},

			/**
			 * @name createPayload
			 * @description Create Payload for conveyancing
			 */
			createPayload() {
				// map asset
				const property = this.property;
				const asset = {
					type: 'property',
					id: property.id,
					data: {
						detail: {
							numberOfOccupiers: property.numberOfOtherOccupiers
						},
						location: {
							address1: property.address1,
							address2: property.address2,
							townCity: property.townCity,
							county: property.county,
							countryId: property.countryId,
							postcode: property.postcode
						},
						mortgage: {
							mortgageTypeId: property.mortgageTypeId,
							outstanding: property.outstandingMortgage,
							lender: property.outstandingMortgageLender,
							number: property.outstandingMortgageNumber,
							amount: property.outstandingMortgageAmount
						}
					}
				};

				// map client
				const client = this.clientList.map((a) => ({
					id: a.id,
					nameTitleId: a.nameTitleId,
					nameTitleName: a.nameTitleName,
					nameGiven: a.nameGiven,
					nameMiddle: a.nameMiddle,
					nameFamily: a.nameFamily,
					communication: [
						{ id: a.landlineId, clientId: a.id, identity: a.landline, type: 'phone', context: 'landline' },
						{ id: a.mobileId, clientId: a.id, identity: a.mobile, type: 'phone', context: 'mobile' },
						{ id: a.emailId, clientId: a.id, identity: a.email, type: 'email', context: null }
					]
				}));

				return {
					organisationId: this.organisationId,
					client,
					asset,
					user: {
						id: this.user.id,
						data: { bank: { name: this.yourPaymentDetails.accountName, account: this.yourPaymentDetails.accountNumber, sortCode: this.yourPaymentDetails.sortCode } }
					},
					process: {
						otherDetails: this.otherDetails,
						payment: { adviserFee: this.yourPaymentDetails.adviserFee },
						product: this.productDetails
					},
					matterType: 'conveyancing',
					processType: 'application_conveyancing'
				};
			},

			/**
			 * @name submit
			 * @description Submit conveyancing referral to the server if forms are valid
			 */
			async submit() {
				if (!this.validateForms()) {
					this.submitFailed = true;
					return;
				}
				const payload = this.createPayload();
				this.$emit('submit', payload);
			},

			/**
			 * @name validateForms
			 * @description Validate all forms in the page
			 * @return {Boolean} Validation result
			 */
			validateForms() {
				this.$refs.product.validateForm();
				this.$refs.payment.validateForm();

				return this.isValid;
			}
		}
	};
</script>
