<!--
	@name app-source-result
	@description Show source results page
	@date 2022/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name" class="pa-3" @click="mouseClickHandler($event)">
		<template v-if="isArchive">
			<p class="text-h6 font-weight-bold mt-2 ml-3 mb-0">
				<v-icon class="mr-2" large color="black">
					mdi-archive-clock
				</v-icon>
				<span>You are viewing product results as they first appeared on {{ iteration.created | moment('MMM Do YYYY, HH:mm') }}</span>
				<span class="text-body-2 font-weight-bold" style="position: relative; left: 2px">{{ iteration.created | moment('ss') }}. </span>
				<a @click="$refs.sourcingSessionHistory.open(processId)">Click here </a> to see the full history of this session.
			</p>
			<p class="text-body2 mt-3 ml-3 mb-6">
				From here you can now print the research PDF, or move back and forth through multiple timestamps to review how filter changes are affecting product results.
			</p>
		</template>

		<v-system-bar v-if="!isArchive" :color="apiHealthStatus" dark height="30" class="px-4">
			<v-tooltip bottom max-width="300">
				<template #activator="{ on }">
					<span
						class="ga--trigger ga--retrieving-live-rates-opened cursor--pointer text-decoration-underline"
						data-ga-id="system-bar"
						@click="openDialog('productLoader')"
						v-on="on"
					>
						Retrieving Live Rates: {{ loadingProducts ? 'Processing' : 'Complete' }}
					</span>
				</template>
				<span>Click to view session specific API messages from our provider partners</span>
			</v-tooltip>
			<v-spacer />
			<v-tooltip bottom max-width="300">
				<template #activator="{ on }">
					<span
						class="ga--trigger ga--api-health-opened cursor--pointer text-decoration-underline"
						data-ga-id="system-bar"
						:data-ga-value="apiHealthStatus"
						@click="openDialog('apiHealth')"
						v-on="on"
					>
						API Health: {{ apiHealthMessage }}
					</span>
				</template>
				<span>Click to view a full API health summary for our provider partners</span>
			</v-tooltip>
		</v-system-bar>

		<!-- Toolbar -->
		<app-source-result-toolbar
			:available-product-count="availableProductCount"
			:outside-criteria-product-count="outsideCriteriaProductCount"
			:loading-products="loadingProducts"
			:iteration="iteration"
			@iteration-updated="setIteration"
			@open-knowledge-bank-criteria-dialog="openDialog('knowledgeBankCriteria')"
			@open-outside-criteria-products-dialog="openDialog('outsideCriteriaProducts', { iterationId, processId, groupedRestrictedProducts })"
			@open-product-loader-dialog="openDialog('productLoader')"
			@open-filters="openFilters"
			@open-back-to-sourcing-dialog="openDialog('backToSourcing')"
		/>

		<div class="d-flex">
			<div v-show="showInsights" class="flex-grow-2 pt-6 px-3 pb-0" :style="{ 'flex-basis': $vuetify.breakpoint.mdAndDown ? '50%' : '40%' }">
				<app-source-result-insights
					ref="insightsWidget"
					:process="process"
					:iteration-id="iterationId"
					:search-criteria="searchCriteria"
					:quick-quote="quickQuote"
					:loading-products="loadingProducts"
					:is-fact-find="isFactFind"
					:product-list="productList"
					@hide-insights="showInsights = false"
					@show-insights="showInsights = true"
					@update-criteria="openFilters"
					@open-borrowing-forecast-dialog="openBorrowingForecast"
				/>
			</div>
			<div
				v-if="!isArchive"
				class="flex-grow-1 pt-6 px-3 pb-0"
				:class="{ 'd-none': showInsights && $vuetify.breakpoint.mdAndDown }"
				:style="{ 'flex-basis': showInsights ? '25%' : '50%' }"
			>
				<v-card elevation="0" class="h-100 d-flex flex-column card--design1 card--design1--4 hover--disabled">
					<v-card-title class="text-body-2 pb-2">
						Feature spotlight
					</v-card-title>
					<v-card-text class="flex-grow-1 d-flex flex-column justify-space-between">
						<template v-if="process">
							<p class="text-body-2 pb-0 mb-3 line-clamp" style="-webkit-line-clamp: 2">
								Our Knowledge Bank integration gives you instant access to market leading product critera. Perform as many searches as you like over multiple
								criteria categories to find out which lenders will accept your client's criteria.
							</p>
							<div>
								<v-btn
									class="ga--trigger ga--knowledge-bank-opened"
									data-ga-id="feature-spotlight"
									small
									style="z-index: 2"
									:disabled="isArchive"
									@click="openDialog('knowledgeBankCriteria')"
								>
									Take a look
								</v-btn>
							</div>
						</template>
						<v-skeleton-loader v-else type="sentences" class="mt-2" />
					</v-card-text>
				</v-card>
			</div>
			<div class="flex-grow-1 pt-6 px-3 pb-0" :style="{ 'flex-basis': showInsights ? '35%' : '50%' }">
				<app-source-result-info
					:process="process"
					:quick-quote="quickQuote"
					:applicants="applicants"
					:property="property"
					:search-criteria="searchCriteria"
					:loading-products="loadingProducts"
					:is-fact-find="isFactFind"
					@open-filters="openFilters"
				/>
			</div>
		</div>

		<div>
			<app-source-result-mortgage
				ref="appSourceResultMortgage"
				:loading-products="loadingProducts"
				:sort-config="searchCriteria.sortConfig"
				:is-quick-quote="!!quickQuote"
				:product-list="productList"
				:selected-product-list="selectedProductList"
				:total-product-count="totalProductCount"
				:items-per-page="searchCriteria.limit"
				:show-hint="showHint"
				:search-bar-hint-status="searchBarHintStatus"
				class="mt-10"
				@open-product-criteria-dialog="openProductCriteriaDialog"
				@open-product-issue-dialog="openProductIssueDialog"
				@open-restrict-product-dialog="openRestrictProductDialog"
				@open-borrowing-forecast-dialog="openBorrowingForecast"
				@search-criteria-updated="updateSearchCriteria"
				@update-selected-product-list="updateSelectedProductList"
				@remove-restriction="removeRestriction"
				@search-bar-focus="searchBarHintStatus = false"
				@page-change="paginationCounter++"
				@reset-sort-config="resetSortConfig"
				@open-product-cost-dialog="openProductCostDialog"
			/>

			<app-source-result-multi-select
				:quick-quote="!!quickQuote"
				:product-list="productList"
				:provider-list="providers"
				:loading-products="loadingProducts"
				:selected-product-list="selectedProductList"
				@open-product-loader-dialog="openDialog('productLoader')"
				@open-restrict-product-dialog="openRestrictProductDialog"
				@open-borrowing-forecast-dialog="openBorrowingForecast"
				@update-selected-product-list="updateSelectedProductList"
				@clear-selected-product-list="clearSelectedProductList"
				@submit-fact-find-products="submitFactFindProducts"
			/>

			<!-- Filter -->
			<app-source-result-filter
				v-if="searchCriteria.filters && form('source_result_filter').value"
				ref="filter"
				:filters="searchCriteria.filters"
				:filter-options-data="filterOptionsData"
				:loading-products="loadingProducts"
				:product-list="productList"
				:available-product-count="availableProductCount"
				:outside-criteria-product-count="outsideCriteriaProductCount"
				@open-outside-criteria-products-dialog="openDialog('outsideCriteriaProducts', { iterationId, processId, groupedRestrictedProducts })"
				@search-criteria-updated="updateSearchCriteria"
			/>
		</div>

		<!-- Dialogs -->
		<common-dialog-knowledge-bank-criteria
			ref="knowledgeBankCriteria"
			:default-criteria="selectedCriteria"
			:close-action-button-text="'Confirm'"
			:minimum-items="0"
			@close-action-confirmed="knowledgeBankCloseActionConfirmed"
			@search-criteria-updated="updateSearchCriteria"
		/>
		<common-dialog-product-criteria ref="productCriteria" />
		<common-dialog-confirm ref="confirm" />
		<app-source-result-dialog-outside-criteria-products
			ref="outsideCriteriaProducts"
			:outside-criteria-product-count="outsideCriteriaProductCount"
			@update-criteria="openFilters"
		/>
		<app-source-result-dialog-product-issue ref="productIssue" />
		<app-source-result-dialog-back-to-sourcing ref="backToSourcing" :is-archive="isArchive" @open-filters="openFilters" />
		<app-source-result-dialog-loading ref="loadingDialog" />
		<app-source-result-dialog-restrict-product ref="restrictProduct" @clear-selected-product-list="clearSelectedProductList" @restrict-product="submitRestrictProduct" />
		<app-common-api-health ref="apiHealth" :providers="providers" />
		<app-source-result-dialog-archive-mode-selection ref="archiveModeSelection" :created="iteration.created" />
		<app-source-result-dialog-sourcing-session-history ref="sourcingSessionHistory" @go-to-session="goToSession" />
		<app-source-result-product-loader-dialog ref="productLoader" :providers="providers" :loading-products="loadingProducts" @retry="addToRequestQueue" />
		<borrowing-forecast-tool ref="borrowingForecast" :borrowing-forecast-active="false" />
		<app-source-result-dialog-product-cost ref="productCostDialog" @open-borrowing-forecast-dialog="openBorrowingForecast" />
		<app-source-result-dialog-websocket-error
			ref="websocketErrorDialog"
			:retry-in-progress="retryInProgress"
			:retry-attempts="retryAttempts"
			@switch-to-standard-server="switchToStandardServer"
			@retry-websocket="retryWebSocket"
		/>
	</div>
</template>

<script>
	import { EventBus, PostMessage, ElementTools, airbrake } from '@/utils';
	import { mapActions, mapGetters, mapState } from 'vuex';
	import { camelCase } from 'lodash';
	import CommonDialogKnowledgeBankCriteria from '@/component/common/dialog/knowledge-bank-criteria';
	import CommonDialogProductCriteria from '@/component/common/dialog/product-criteria';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppSourceResultDialogBackToSourcing from '@/component/app/source-result/dialog/back-to-sourcing';
	import AppSourceResultDialogProductIssue from '@/component/app/source-result/dialog/product-issue';
	import AppSourceResultDialogRestrictProduct from '@/component/app/source-result/dialog/restrict-product';
	import AppSourceResultDialogOutsideCriteriaProducts from '@/component/app/source-result/dialog/outside-criteria-products';
	import AppSourceResultDialogLoading from '@/component/app/source-result/dialog/loading';
	import AppCommonApiHealth from '@/component/app/common/api-health';
	import AppSourceResultDialogArchiveModeSelection from '@/component/app/source-result/dialog/archive-mode-selection';
	import AppSourceResultDialogSourcingSessionHistory from '@/component/common/dialog/sourcing-session-history';
	import AppSourceResultProductLoaderDialog from '@/component/app/source-result/product-loader/dialog';
	import AppSourceResultInsights from './insights';
	import AppSourceResultInfo from './info';
	import AppSourceResultFilter from './filter';
	import AppSourceResultMortgage from '@/component/app/source-result/mortgage';
	import AppSourceResultMultiSelect from '@/component/app/source-result/multi-select';
	import AppSourceResultToolbar from '@/component/app/source-result/toolbar';
	import BorrowingForecastTool from '@/component/app/calculator/borrowing-forecast-tool';
	import AppSourceResultDialogProductCost from '@/component/app/source-result/dialog/product-cost';
	import AppSourceResultDialogWebsocketError from '@/component/app/source-result/dialog/websocket-error';

	export default {
		name: 'source-result',

		components: {
			CommonDialogKnowledgeBankCriteria,
			CommonDialogProductCriteria,
			CommonDialogConfirm,
			AppSourceResultDialogBackToSourcing,
			AppSourceResultDialogProductIssue,
			AppSourceResultDialogRestrictProduct,
			AppSourceResultDialogOutsideCriteriaProducts,
			AppSourceResultDialogLoading,
			AppCommonApiHealth,
			AppSourceResultDialogSourcingSessionHistory,
			AppSourceResultDialogArchiveModeSelection,
			AppSourceResultProductLoaderDialog,
			AppSourceResultInsights,
			AppSourceResultInfo,
			AppSourceResultFilter,
			AppSourceResultMortgage,
			AppSourceResultMultiSelect,
			AppSourceResultToolbar,
			BorrowingForecastTool,
			AppSourceResultDialogProductCost,
			AppSourceResultDialogWebsocketError
		},

		data() {
			return {
				showInsights: false,
				process: null,
				processId: null,
				iteration: {},
				iterationId: null,
				applicants: [],
				property: {},
				loadingProducts: true,
				quickQuote: null,
				websocketConnectOptions: {
					providers: [],
					retry: false
				},
				tabConfig: [{ name: 'mortgage', title: 'Lifetime & Retirement Mortgages', alerts: 0 }],
				providers: [],
				availableProductCount: 0,
				outsideCriteriaProductCount: 0,
				totalProductCount: 0,
				productList: [],
				groupedRestrictedProducts: {},
				selectedProductList: [],
				filterOptionsData: {},
				searchCriteria: { limit: 10 },
				showHint: false,
				requestQueue: [],
				idleNotificationDisplayed: false,
				searchBarHintStatus: false,
				paginationCounter: 0,
				filterPanelAutoOpened: false,
				entityError: false,
				selectedCriteria: [],
				retryAttempts: 0,
				retryInProgress: false
			};
		},

		computed: {
			...mapGetters('Account', ['membership']),
			...mapGetters('CmsForm', ['form']),
			...mapState('CmsSite', ['site']),

			apiHealthProviders() {
				return this.providers.filter((p) => p.health !== false);
			},

			apiHealthErrors() {
				return this.apiHealthProviders.filter((p) => p.apiHealth?.status && ['error'].includes(p.apiHealth.status?.toLowerCase())).length;
			},

			apiHealthWarnings() {
				return this.apiHealthProviders.filter((p) => p.apiHealth?.status && ['warning'].includes(p.apiHealth.status?.toLowerCase())).length;
			},

			apiHealthIssues() {
				return this.apiHealthErrors + this.apiHealthWarnings;
			},

			apiHealthStatus() {
				if (!this.apiHealthProviders.length) return 'grey lighten-1';
				if (this.apiHealthErrors) return 'error';
				if (this.apiHealthWarnings) return 'warning';
				return 'success';
			},

			apiHealthMessage() {
				return this.apiHealthStatus === 'success' ? 'Good' : `${this.apiHealthIssues} Issue${this.apiHealthIssues !== 1 ? 's' : ''}`;
			},

			apiMessages() {
				return this.providers.filter((p) => p.status && p.status.toLowerCase() === 'failed').length;
			},

			tabs() {
				return this.tabConfig;
			},

			isFactFind() {
				return this.$route.query && !!this.$route.query.factfind;
			},

			isArchive() {
				return this.$route.query && this.$route.query.view === 'archive';
			},

			isReloadSession() {
				return this.$route.query && this.$route.query.view === 'reload';
			},

			webSocketEnabled() {
				return !this.$route.query.websocket || this.$route.query.websocket !== 'false';
			},

			airSelect() {
				return this.membership('select').id && !this.membership('select').bypass;
			}
		},

		watch: {
			loadingProducts: {
				handler(val) {
					EventBus.$emit('air-select-disabled', val);
				},
				immediate: true
			}
		},

		created() {
			this.iterationId = this.$route.query.iteration;
			this.processId = this.$route.params.processId;

			if (this.isFactFind) {
				this.postMessage = new PostMessage({ origin: process.env.VUE_APP_FACTFIND_ORIGIN });
				window.onbeforeunload = () => {
					this.postMessage.send('result', { message: 'Window is closed without submitting any product' });
				};
			}

			if (!isNaN(this.processId)) {
				this.$router.push('/account');
				return;
			}

			EventBus.$on('toggle-air-select-bypass', this.airSelectToggleChanged);
			EventBus.$on('trading-style-selected', this.tradingStyleSelected);
		},

		mounted() {
			if (isNaN(this.processId)) {
				this.init();
			}

			if (this.isArchive) this.showInsights = false;
		},

		destroyed() {
			EventBus.$off('toggle-air-select-bypass', this.airSelectToggleChanged);
			EventBus.$off('trading-style-selected', this.tradingStyleSelected);
			this.$websocket.$off('message', this.onMessageReceived);
			this.$websocket.$off('error', this.onWebSocketError);
			this.$websocket.$off('close', this.onWebSocketDisconnected);
		},

		methods: {
			...mapActions('AppSourceIteration', ['getIteration']),
			...mapActions('AppSourceProcess', ['getProcess', 'getProcessList']),
			...mapActions('AppClient', ['loadClient']),
			...mapActions('AppClientAsset', ['loadClientAsset']),
			...mapActions('CmsConfig', ['loadConfig']),
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Option', ['getCountryOption', 'getGenderOption']),
			...mapActions('LifetimeMortgageSourcing', ['submitIteration', 'fetchResults']),

			async init() {
				this.$refs.loadingDialog.open('Loading session...');
				this.fetchProcess();
				this.fetchProcessList();
				this.loadForm('source_result_filter');

				let iteration = await this.fetchIteration();

				if (!iteration) return;

				if (iteration.data?.imported) {
					this.openIterationNotFoundDialog();
					return;
				}

				let isArchiveConfirmed = false;

				if (iteration.iterationHasDataSaved && !this.isArchive && !this.isReloadSession) {
					isArchiveConfirmed = await new Promise((resolve) => {
						this.$refs.archiveModeSelection.open(resolve);
					});
				}

				if (isArchiveConfirmed) {
					let query = { ...this.$route.query, view: 'archive' };
					this.$router.replace({ query });
				}

				if (this.isArchive) {
					this.goToSession(iteration);
					this.$refs.productLoader.close();
					return;
				}

				if (iteration.iterationHasDataSaved) {
					await this.forceCreateNewIteration(iteration);
				}

				await this.loadConfig({ type: 'lifetime-mortgage', name: 'sourcing' }).then((response) => {
					if (!response.error) {
						let providers = this.providers;
						if (response.data.api?.organisation) providers.push(...response.data.api.organisation);
						this.providers = providers.map((i) => ({ ...i, status: null }));
					}
				});

				this.$refs.loadingDialog.close();

				if (this.webSocketEnabled) {
					// init websocket
					this.websocketConnectOptions.providers = this.providers;
					this.websocketConnectOptions.retry = false;
					this.initWebSocket();
				} else {
					// Fallback request to fetch products with AJAX rather than WS
					this.fetchProductsFallback(this.providers);
				}
			},

			initWebSocket() {
				if (this.entityError) return;

				this.$websocket.$on('message', this.onMessageReceived);
				this.$websocket.$on('error', this.onWebSocketError);
				this.$websocket.$on('close', this.onWebSocketDisconnected);
				this.fetchProducts(this.websocketConnectOptions.providers, this.websocketConnectOptions.retry);
			},

			fetchProcess() {
				this.getProcess(this.processId)
					.then(async({ data: process }) => {
						this.process = process;
						if (process.matterEntity) {
							if (process.data.clients && !process.data.clients.every((c) => process.matterEntity.find((me) => me.entity === 'client' && me.entityId === c.id)))
								this.openClientRelationshipError();
							else this.setMatterEntity(process);
						} else {
							this.quickQuote = process.data;
							if (this.quickQuote.genderId) {
								let gender = await this.getGenderOption(this.quickQuote.genderId);
								this.$set(this.quickQuote, 'gender', gender);
							}
							if (this.quickQuote.countryId) {
								let country = await this.getCountryOption(this.quickQuote.countryId);
								this.$set(this.quickQuote, 'country', country);
							}
						}
					})
					.catch((error) => {
						if (error?.status === 404) {
							if (this.$route.name === 'result') this.$router.push('/account');
						} else this.openNoResultDialog();
					});
			},

			fetchProcessList() {
				this.getProcessList({
					where: {
						'process_type.name_unique': 'sourcing',
						'process.id': { c: '!=', v: this.processId },
						'process.created': { c: '>=', v: this.$moment().subtract(3, 'months').format('YYYY-MM-DD HH:mm:ss') }
					}
				})
					.then(({ data }) => {
						if (!data.count) this.showHint = true;
					})
					.catch((error) => {
						airbrake.notify({
							error: error,
							params: { processId: this.processId, component: this.$options.name, parent: this.$parent?.$options?.name }
						});
					});
			},

			setMatterEntity(process) {
				const clientList = process.matterEntity.filter((me) => me.entity === 'client');
				const asset = process.matterEntity.find((me) => me.entity === 'asset');

				// Check if client and asset exist
				clientList.forEach((client) => this.loadClient(client.entityId).catch(() => this.openEntityNotFoundDialog('Client')));
				this.loadClientAsset({ clientId: clientList[0].entityId, id: asset.entityId }).catch(() => this.openEntityNotFoundDialog('Property'));

				// Set clients and asset from process data
				this.applicants = process.data.clients?.map((client) => {
					let applicant = {};
					Object.keys(client).forEach((key) => (applicant[camelCase(key)] = client[key]));
					return applicant;
				});

				this.property = process.data.asset;
			},

			onWebSocketError() {
				this.openWebSocketErrorDialog('Connection Error');
				this.retryInProgress = false;
			},

			onWebSocketDisconnected() {
				if (this.providers.some((p) => p.status === 'Pending') && this.$route.name === 'result') {
					this.openWebSocketErrorDialog('Disconnection Error');
				}
			},

			onMessageReceived(message) {
				if (message.status === 200 && this.retryInProgress) {
					this.retryInProgress = false;
					this.closeWebSocketErrorDialog();
				}

				if (message.type !== 'sourcing' || !message.data?.status) return;

				const data = message.data;

				if (data && Array.isArray(data.products)) {
					// if we receive less products than we already have, something went wrong, so suppress
					if (data.meta.count > this.totalProductCount) {
						this.availableProductCount = data.meta.availableProductCount;
						this.outsideCriteriaProductCount = data.meta.outsideCriteriaProductCount;
						this.totalProductCount = data.meta.count;
						this.groupedRestrictedProducts = data.meta.groupedRestrictedProducts;
						this.productList = data.products;
						this.filterOptionsData = data.filters;

						this.$nextTick().then(() => this.$refs.filter?.updateFilterOptionsData());
					}
				}

				data.status.map((s) => {
					let provider = this.providers.find((p) => p.nameUnique === s.provider);
					if (provider.status.toLowerCase() === 'pending') {
						provider.status = s.status;
						if (s.status.toLowerCase() === 'failed') provider = this.handleFailedRequest(provider, s);
					}
				});

				if (data.complete) {
					// update product list
					if (data.updateOnComplete) this.productList = data.products;

					// all messages have completed, let's proceed
					if (this.requestQueue.length) {
						this.fetchProducts(this.requestQueue, true);
						this.requestQueue = [];
						return;
					}

					if (!this.productList.length && !this.searchCriteria.search && !this.providers.some((p) => p.status === 'Failed')) {
						this.$refs.productLoader.close();
						this.openNoResultDialog();
					}

					this.loadingProducts = false;

					// Update insights component
					if (this.insightsActive(this.searchCriteria.filters?.maximumMonthlyRepaymentAmount)) {
						this.showInsights = true;
						let payload = { ...this.searchCriteria };
						payload.sortConfig = this.mapSortConfig(payload);
						this.$refs.insightsWidget.init(payload);
					} else this.showInsights = false;

					if (!this.providers.some((p) => p.status === 'Failed')) {
						this.$refs.productLoader.close();
					} else {
						// Google Analytics event
						window.dataLayer.push({
							event: 'retrievingLiveRatesOpened',
							'gtm.element.dataset.gaId': 'event',
							'gtm.element.dataset.gaValue': 'provider failed to return products'
						});
						this.$refs.productLoader.open();
					}
				}
			},

			insightsActive(payment) {
				return !Number(payment) && this.availableProductCount > 0 && !this.quickQuote;
			},

			mapSortConfig(payload) {
				return payload.sortConfig.sortBy.length ? payload.sortConfig.sortBy.map((s, i) => ({ key: s, direction: payload.sortConfig.sortDesc[i] ? 'desc' : 'asc' })) : [];
			},

			handleFailedRequest(provider, response) {
				//Specific error message
				if (response?.error?.body?.message) this.$set(provider, 'message', response.error.body.message);

				//Expired token
				if (response?.error?.tokenExpired) {
					this.$set(
						provider,
						'message',
						`Your ${provider.name} credentials have expired. Click Update Credentials to continue sourcing products under your ${provider.name} account. Alternatively, click Remove Credentials to source anonymously.`
					);
					this.$set(provider, 'refreshToken', true);
				}
				return provider;
			},

			addToRequestQueue(provider) {
				// Reset refreshToken to avoid frozen UI
				this.$set(provider, 'refreshToken', false);
				if (this.loadingProducts) {
					provider.status = 'queued';
					this.requestQueue.push(provider);
				} else {
					if (this.webSocketEnabled) this.fetchProducts([provider], true);
					else this.fetchProductsFallback([provider], true);
				}
			},

			fetchProducts(providers, retry = false) {
				providers.forEach((p) => (p.status = 'Pending'));
				this.loadingProducts = true;
				this.$refs.productLoader.open();

				if (!this.isArchive && !this.quickQuote && !this.filterPanelAutoOpened) {
					this.$refs.filter.openPanels();
					this.filterPanelAutoOpened = true;
				}

				const options = {
					retry,
					type: 'sourcing',
					iterationId: this.iterationId,
					processId: this.processId,
					providers: providers.map((p) => p.nameUnique)
				};
				// this.searchCriteria is persistent and should therefore contain all value that we need
				const searchOptions = (({ limit, search, viewOptions }) => ({ limit, search, viewOptions }))(this.searchCriteria);
				const payload = { ...options, ...searchOptions };
				Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key]);
				// we should reset to page 1
				payload.offset = 0;
				this.$refs.appSourceResultMortgage.setPage(1);

				if (this.site?.name) payload.domain = this.site.name;

				this.$websocket.$sendMessage(payload);
			},

			resetSortConfig() {
				let sortConfig = (this.iteration.data?.sortConfig || []).reduce(
					(acc, cur) => {
						if (cur.key && acc.sortBy) acc.sortBy.push(cur.key);
						if (cur.direction && acc.sortDesc) acc.sortDesc.push(cur.direction === 'desc');
						return acc;
					},
					{ sortBy: [], sortDesc: [] }
				);

				this.searchCriteria = { ...this.searchCriteria, sortConfig };
			},

			fetchProductsFallback(providers, retry = false) {
				providers.forEach((p) => (p.status = 'Pending'));
				this.loadingProducts = true;
				this.$refs.productLoader.open();

				const options = {
					retry,
					type: 'sourcing',
					iterationId: this.iterationId,
					processId: this.processId,
					providers: providers.map((p) => p.nameUnique)
				};

				// this.searchCriteria is persistent and should therefore contain all value that we need
				const searchOptions = (({ limit, search, viewOptions }) => ({ limit, search, viewOptions }))(this.searchCriteria);
				const payload = { ...options, ...searchOptions };
				Object.keys(payload).forEach((key) => payload[key] === undefined && delete payload[key]);
				// we should reset to page 1
				payload.offset = 0;
				this.$refs.appSourceResultMortgage.setPage(1);

				if (this.site?.name) payload.site = this.site.name;

				this.fetchResults(payload).then(({ data }) => {
					if (!data.status) return;

					if (data && Array.isArray(data.products)) {
						this.availableProductCount = data.meta.availableProductCount;
						this.outsideCriteriaProductCount = data.meta.outsideCriteriaProductCount;
						this.totalProductCount = data.meta.count;
						this.groupedRestrictedProducts = data.meta.groupedRestrictedProducts;
						this.productList = data.products;
						this.filterOptionsData = data.filters;

						this.$nextTick().then(() => this.$refs.filter?.updateFilterOptionsData());
					}

					data.status.map((s) => {
						let provider = this.providers.find((p) => p.nameUnique === s.provider);
						if (provider.status.toLowerCase() === 'pending') {
							provider.status = s.status;
							if (s.status.toLowerCase() === 'failed') provider = this.handleFailedRequest(provider, s);
						}
					});

					if (!this.providers.some((p) => p.status === 'Pending')) {
						if (this.requestQueue.length) {
							this.fetchProductsFallback(this.requestQueue, true);
							this.requestQueue = [];
							return;
						}

						if (!this.productList.length && !this.searchCriteria.search && !this.providers.some((p) => p.status === 'Failed')) {
							this.$refs.productLoader.close();
							this.openNoResultDialog();
						}

						this.loadingProducts = false;

						if (!this.providers.some((p) => p.status === 'Failed')) {
							this.$refs.productLoader.close();
						} else this.$refs.productLoader.open();
					}
				});
			},

			async fetchIteration() {
				return this.getIteration(this.iterationId)
					.then(({ data }) => this.setIteration(data))
					.catch(() => {
						this.openIterationNotFoundDialog();
					});
			},

			setIteration(iteration) {
				this.iteration = iteration;

				let criteria = iteration.data.criteria;
				this.selectedCriteria = iteration.data.selectedCriteria || this.selectedCriteria;
				let filters = iteration.data.filters;
				let sortConfig = (iteration.data?.sortConfig || []).reduce(
					(acc, cur) => {
						if (cur.key && acc.sortBy) acc.sortBy.push(cur.key);
						if (cur.direction && acc.sortDesc) acc.sortDesc.push(cur.direction === 'desc');
						return acc;
					},
					{ sortBy: [], sortDesc: [] }
				);

				this.searchCriteria.filters = null; // reset filters for the component to re-render with new filters

				this.$nextTick().then(() => {
					this.searchCriteria = { ...this.searchCriteria, criteria, filters, sortConfig };
					if (criteria) this.searchCriteria.criteria = criteria;
				});

				return iteration;
			},

			setQueryParams(data) {
				let query = { iteration: data.iterationId };
				if (this.isFactFind) query.factfind = true;
				if (this.isArchive) query.view = 'archive';
				if (!this.webSocketEnabled) query.websocket = false;
				this.$router.replace({ query });
			},

			knowledgeBankCloseActionConfirmed(args) {
				this.$refs.knowledgeBankCriteria.close();
				this.updateSearchCriteria(args);
			},

			async updateSearchCriteria(searchCriteria, restrictions, createNewIteration) {
				this.loadingProducts = true;

				this.availableProductCount = 0;
				this.outsideCriteriaProductCount = 0;
				this.totalProductCount = 0;
				this.groupedRestrictedProducts = [];
				this.productList = [];
				this.searchCriteria = { ...this.searchCriteria, ...searchCriteria };

				let payload = { ...this.searchCriteria };
				if (restrictions) payload = { ...payload, ...restrictions };
				payload.sortConfig = this.mapSortConfig(payload);

				// If we've passed the boolean of createNewIteration to this function, add it to the payload
				if (createNewIteration) {
					payload.createNewIteration = true;
				}

				let iterationRequest = {
					iterationId: this.iterationId,
					processId: this.processId,
					data: payload
				};

				const { data } = await this.submitIterationRequest(iterationRequest);

				// Update URL if iteration id has changed
				let newIteration = false;
				if (data.iterationId !== this.iterationId) {
					newIteration = true;
					this.iterationId = data.iterationId;
					this.setQueryParams(data);
					this.fetchIteration();
				}

				if (data.products && data.meta) {
					this.availableProductCount = data.meta.availableProductCount;
					this.outsideCriteriaProductCount = data.meta.outsideCriteriaProductCount;
					this.totalProductCount = data.meta.count;
					this.groupedRestrictedProducts = data.meta.groupedRestrictedProducts;
					this.productList = data.products;
					this.loadingProducts = false;
					this.filterOptionsData = data.filters;

					this.$nextTick().then(() => {
						this.$refs.filter?.updateFilterOptionsData();
						// Trigger insights request
						if (this.insightsActive(payload.filters?.maximumMonthlyRepaymentAmount)) {
							// Only trigger insights widget if new iteration and 0 payments
							if (newIteration) {
								this.showInsights = true;
								this.$refs.insightsWidget.init(payload);
							}
						} else this.showInsights = false; // Hide insights if we now have a payment
					});
				} else {
					if (this.webSocketEnabled) this.fetchProducts(this.providers);
					else this.fetchProductsFallback(this.providers);
				}

				// Prevent createNewIteration flag from persisting
				delete this.searchCriteria.createNewIteration;
			},

			async airSelectToggleChanged() {
				if (this.isArchive) return;

				this.clearSelectedProductList(); // Clear any selected products list

				await this.loadConfig({ type: 'lifetime-mortgage', name: 'sourcing' }).then((response) => {
					if (!response.error) {
						this.providers = [];
						let providers = this.providers;
						if (response.data.api?.organisation) providers.push(...response.data.api.organisation);
						this.providers = providers.map((i) => ({ ...i, status: null }));
					}
				});

				this.updateSearchCriteria({}, {}, true);
			},

			async tradingStyleSelected() {
				if (this.isArchive) return;

				this.clearSelectedProductList(); // Clear any selected products list

				await this.loadConfig({ type: 'lifetime-mortgage', name: 'sourcing' }).then((response) => {
					if (!response.error) {
						this.providers = [];
						let providers = this.providers;
						if (response.data.api?.organisation) providers.push(...response.data.api.organisation);
						this.providers = providers.map((i) => ({ ...i, status: null }));
					}
				});

				this.updateSearchCriteria({}, {}, true);
			},

			submitRestrictProduct({ products, reason }) {
				let productsMapped = products.map((p) => ({ id: p.id, reason }));
				this.updateSearchCriteria(this.searchCriteria, { addRestrictions: productsMapped });
			},

			removeRestriction(product) {
				this.updateSearchCriteria(this.searchCriteria, { removeRestriction: { id: product.id } });
			},

			/**
			 * @name forceCreateNewIteration
			 * @description Force create a new iteration - used on page refresh or select toggle
			 * @param {Object} iteration
			 */
			async forceCreateNewIteration(iteration) {
				let iterationData = { ...iteration.data };
				if (iterationData.s3Key) delete iterationData.s3Key;

				const { data } = await this.submitIterationRequest({
					iterationId: this.iterationId,
					processId: iteration.processId,
					data: { ...iterationData, createNewIteration: true }
				});

				let query = { iteration: data.iterationId };
				if (this.isFactFind) query.factfind = true;
				if (!this.webSocketEnabled) query.websocket = false;
				this.$router.replace({ query });
				this.iterationId = data.iterationId;
				this.fetchIteration();
			},

			/**
			 * @name updateSelectedProductList
			 * @param {Object} state
			 * @param {Object} data
			 */
			updateSelectedProductList({ event, product }) {
				let selectedProductList = [...this.selectedProductList];

				if (event) {
					//If adding product, first check it isn't already present
					if (selectedProductList.find((p) => p.id === product.id)) return;
					selectedProductList.push({ ...product, iterationId: this.iterationId });
				} else selectedProductList = selectedProductList.filter((p) => p.id !== product.id);
				this.selectedProductList = selectedProductList;
			},

			clearSelectedProductList() {
				this.selectedProductList = [];
			},

			/**
			 * @name openFilters
			 * @param {Number} restrictionCode
			 */
			openFilters(restrictionCode) {
				this.$refs.filter.openPanels(restrictionCode);
			},

			/**
			 * @name openDialog
			 * @param {String} ref
			 * @param {Object} payload
			 */
			openDialog(ref, payload) {
				this.$refs[ref].open(payload);
			},

			/**
			 * @name openProductCriteriaDialog
			 * @param {Object} product The product object
			 */
			openProductCriteriaDialog(product) {
				this.$refs.productCriteria.open(this.iterationId, product);
			},

			/**
			 * @name openProductIssueDialog
			 * @param {Object} product The product object
			 */
			openProductIssueDialog(product) {
				this.$refs.productIssue.open(product);
			},

			/**
			 * @name openRestrictProductDialog
			 * @param {Object} product The product object
			 */
			openRestrictProductDialog(product) {
				this.$refs.restrictProduct.open(product);
			},

			/**
			 * @name openBorrowingForecast
			 * @param {Object} product The product object
			 */
			openBorrowingForecast(product, productTwo = false, propertyData = false, source = false) {
				const options = {
					source: 'results',
					calculatorType: 0,
					propertyActive: true
				};

				const term = this.searchCriteria.filters.term;
				const loanPaymentOptions = {
					'38adf366-934e-4c25-9165-56fd809c678f': 4, //Roll Up (No Payments permitted)
					'fed7de7b-4c86-4e2c-b028-6534185f2b6d': 4, //Voluntary (Adhoc) Payments
					'90649bf3-09be-45c4-aaf9-6266dbb4bfc7': 2, //Full Interest Serviced Mortgage
					'4a38fc40-e285-4019-a486-03b623a65066': 3, //Capital and Interest Mortgage
					'9779385a-2a82-46d2-bbaa-6da1df69017d': 5, //Interest Serviced (partial to full)
					'5f018d6a-4f09-45de-be42-3c140ad8317a': 8 //Mandatory Payments
				};

				let paymentType = loanPaymentOptions[product.loanPaymentTypeId];
				const productData = {
					annualEquivalentRate: product.annualEquivalentRate.toFixed(2),
					initialBorrowing: product.advance,
					interestRate: paymentType === 5 ? product.monthlyRate.toFixed(2) : product.annualEquivalentRate.toFixed(2),
					monthlyEquivalentRate: product.monthlyRate.toFixed(2),
					monthlyPayments: product.payments?.totalPayments ? Number(product.payments.totalPayments).toFixed(2) : 0,
					name: product.name,
					paymentsOverrides: [],
					paymentTermType: 'entire',
					paymentType: paymentType,
					productFee: 0, // paymentType === 5 ? 0 : product.completionFee?.amount,
					term: term
				};

				// Are we dealing with split rates?
				if (product.payments?.rateChanges?.length) productData.rateChanges = product.payments.rateChanges;

				// Are we dealing with split payments?
				if (product.payments?.terms?.length) {
					if (product.payments?.terms?.length > 1) {
						productData.paymentTerm = product.payments.terms[0].end;
						productData.paymentTermType = 'fixed';
						// If we have multiple payment terms, set all but first as payment overrides
						productData.paymentsOverrides = product.payments.terms;
					} else if (product.payments?.contract?.terms?.length && product.payments.contract.terms[0].end != term * 12) {
						productData.paymentTerm = product.payments.contract.terms[0].end;
						productData.paymentTermType = 'fixed';
					} else if (product.payments?.voluntary?.terms?.length && product.payments.voluntary.terms[0].end != term * 12) {
						productData.paymentTerm = product.payments.voluntary.terms[0].end;
						productData.paymentTermType = 'fixed';
					}
				}

				// Terms should be identical, even if comparison not active
				let comparisonProductData = { term: term };
				if (productTwo) {
					// Ensure product two comparison is active
					options.comparisonActive = true;
					// Assign product two data
					paymentType = loanPaymentOptions[productTwo.loanPaymentTypeId];
					comparisonProductData = Object.assign(comparisonProductData, {
						annualEquivalentRate: productTwo.annualEquivalentRate.toFixed(2),
						initialBorrowing: productTwo.advance,
						interestRate: paymentType === 5 ? productTwo.monthlyRate.toFixed(2) : productTwo.annualEquivalentRate.toFixed(2),
						monthlyEquivalentRate: productTwo.monthlyRate.toFixed(2),
						monthlyPayments: productTwo.payments?.totalPayments ? Number(productTwo.payments.totalPayments).toFixed(2) : 0,
						name: productTwo.name,
						paymentsOverrides: [],
						paymentTermType: 'entire',
						paymentType: paymentType,
						productFee: 0 // paymentType === 5 ? 0 : productTwo.completionFee?.amount
					});

					// Are we dealing with split rates?
					if (productTwo.payments?.rateChanges?.length) comparisonProductData.rateChanges = productTwo.payments.rateChanges;

					// Are we dealing with split payments?
					if (productTwo.payments?.terms?.length) {
						if (productTwo.payments?.terms?.length > 1) {
							comparisonProductData.paymentTermType = 'fixed';
							comparisonProductData.paymentTerm = productTwo.payments.terms[0].end;
							// If we have multiple payment terms, set all but first as payment overrides
							comparisonProductData.paymentsOverrides = productTwo.payments.terms;
						} else if (productTwo.payments?.contract?.terms?.length && productTwo.payments.contract.terms[0].end != term * 12) {
							comparisonProductData.paymentTermType = 'fixed';
							comparisonProductData.paymentTerm = productTwo.payments.contract.terms[0].end;
						} else if (productTwo.payments?.voluntary?.terms?.length && productTwo.payments.voluntary.terms[0].end != term * 12) {
							comparisonProductData.paymentTermType = 'fixed';
							comparisonProductData.paymentTerm = productTwo.payments.voluntary.terms[0].end;
						}
					}
				}

				if (!propertyData) {
					if (this.quickQuote) {
						propertyData = {
							name: this.quickQuote.postcode,
							propertyValue: this.quickQuote.valuation
						};
					} else {
						const { address1, address2, townCity, postcode } = this.property?.data?.location;
						propertyData = {
							name: [address1, address2, townCity, postcode].filter(Boolean).join(', '),
							propertyValue: this.property.data.valuation.value
						};
					}
				}

				this.$refs.borrowingForecast.open(options, productData, comparisonProductData, propertyData, source);
			},

			/**
			 * @name openNoResultDialog
			 */
			openNoResultDialog() {
				this.$refs.confirm
					.open('Error', 'No products found against the selected criteria.', {
						system_bar_color: 'error darken-2',
						app_bar_color: 'error',
						buttons: { yes: 'Back to sourcing', no: false },
						persistent: true
					})
					.finally(() => {
						this.$router.push('/source');
					});
			},

			/**
			 * @name openEntityNotFoundDialog
			 */
			openEntityNotFoundDialog(entity) {
				if (this.entityError) return;
				this.entityError = true;
				this.$refs.confirm
					.open('Error', `${entity} not found.`, {
						system_bar_color: 'error darken-2',
						app_bar_color: 'error',
						buttons: { yes: 'Back to sourcing', no: false },
						persistent: true
					})
					.finally(() => {
						this.$router.push('/source');
					});
			},

			/**
			 * @name openIterationNotFoundDialog
			 */
			openIterationNotFoundDialog() {
				this.$refs.confirm
					.open('Error', `Session not found.`, {
						system_bar_color: 'error darken-2',
						app_bar_color: 'error',
						buttons: { yes: 'Back to sourcing', no: false },
						persistent: true
					})
					.finally(() => {
						this.$router.push(`/source/${this.processId}`);
					});
			},

			openWebSocketErrorDialog(title) {
				this.$refs.websocketErrorDialog?.open(title);
			},

			closeWebSocketErrorDialog() {
				this.$refs.websocketErrorDialog?.close();
			},

			retryWebSocket() {
				this.retryInProgress = true;
				this.retryAttempts++;
				this.$websocket.$retry();
			},

			switchToStandardServer() {
				window.location.href = window.location.href + '&websocket=false';
			},

			/**
			 * @name openClientRelationshipError
			 */
			openClientRelationshipError() {
				if (this.entityError) return;
				this.entityError = true;
				this.$refs.confirm
					.open(
						'Error',
						`This session was originally sourced with a client relationship that no longer exists. Click "back to sourcing" to proceed using the current client relationship status.`,
						{
							system_bar_color: 'error darken-2',
							app_bar_color: 'error',
							buttons: { yes: 'Back to sourcing', no: false },
							persistent: true
						}
					)
					.finally(() => {
						this.$router.push(`/source/${this.processId}`);
					});
			},

			/**
			 * @name submitFactFindProducts
			 * @description Submit selected products into fact find application
			 */
			submitFactFindProducts() {
				window.onbeforeunload = null;
				this.postMessage.send('result', {
					message: 'success',
					products: this.selectedProductList,
					processId: this.processId,
					iterationId: this.iterationId,
					term: this.searchCriteria.filters.term
				});
			},

			/**
			 * @name mouseClickHandler
			 * @description mouse click event handler for results page
			 */
			mouseClickHandler(event) {
				if (this.idleNotificationDisplayed) return;

				if (event.target.closest('.v-pagination__item')) this.paginationCounter++;
				if (this.paginationCounter > 4) this.displaySearchBarHint(this.paginationCounter);
			},

			/**
			 * @name displaySearchBarHint
			 * @description Displays a hint for the search bar
			 */
			displaySearchBarHint(occurences) {
				// Google Analytics event
				window.dataLayer.push({
					event: 'paginationHintOpened',
					'gtm.element.dataset.gaId': 'event',
					'gtm.element.dataset.gaValue': `pagination used ${occurences} times`
				});

				this.idleNotificationDisplayed = true;

				ElementTools.fireNotification(
					this.$el,
					'info',
					'Looking for something? Use the search bar to isolate products from a single Provider, an entire Product range or an individual Product.',
					{ timeout: 0, close: true }
				);

				this.searchBarHintStatus = true;
			},

			async goToSession(iteration) {
				this.$refs.loadingDialog.open('Loading session...');
				this.$refs.appSourceResultMortgage.setPage(1);
				this.$refs.appSourceResultMortgage.setSearch();

				const payload = { ...iteration.data, limit: this.searchCriteria.limit, createNewIteration: false };

				// Reset certain options in case they were saved alongside iteration.data
				if (payload.search) delete payload.search;
				payload.offset = 0;
				payload.viewOptions = [];

				const { data } = await this.submitIterationRequest({ iterationId: iteration.id, processId: this.processId, data: payload });

				this.$refs.loadingDialog.close();

				// Update URL if iteration id has changed
				if (data.iterationId !== this.iterationId) {
					this.iterationId = data.iterationId;
					this.setQueryParams(data);
					this.fetchIteration();
				}

				if (data.products && data.meta) {
					this.availableProductCount = data.meta.availableProductCount;
					this.outsideCriteriaProductCount = data.meta.outsideCriteriaProductCount;
					this.totalProductCount = data.meta.count;
					this.groupedRestrictedProducts = data.meta.groupedRestrictedProducts;
					this.productList = data.products;
					this.loadingProducts = false;
					this.filterOptionsData = data.filters;

					this.$nextTick().then(() => this.$refs.filter?.updateFilterOptionsData());
				}
			},

			async submitIterationRequest(payload) {
				return this.submitIteration(payload)
					.then((response) => response)
					.catch(() => {
						this.openIterationNotFoundDialog();
					});
			},

			/**
			 * @name openProductCostDialog
			 * @param {Object} product The product object
			 */
			openProductCostDialog(product) {
				this.$refs.productCostDialog.open(product);
			}
		}
	};
</script>
